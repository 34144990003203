
body { 
    background-color: #1F2833 ;
  }

.menu-flags{
    width: 80px;
}

body, html {
  height: 100%;
  margin: 0;
}

.supportedServices{
  padding-top: 10px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 20px;
}

.tool {
  position: relative;
  display: inline-block;
  padding-top: 10px;
  padding-left: 2px;
  padding-right: 2px;
}

.tooltiptext {
  visibility: hidden;
 /* max-width: 85px;*/
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  word-wrap: break-word;

  /* Position the tooltip */
  z-index: 1;
  opacity: 80%;
  
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%); /* Safari iOS */
  transform: translateX(-50%);

  max-width: 78px;
  max-height: 78px;
  width: 100%;
  height: 100%;
  border-radius: 24%;

}

div.tool:hover .tooltiptext {
  visibility: visible;
}

button.searchButton{
  height:44px; 
  max-width:200px; 
  z-index:0 !important;
  color:#66FCF1;
  border-color:#66FCF1 !important;
}

.searchButton:hover, .pageSelectButton:hover, button.active {
  background-color: #66FCF1 !important;
  color: #1F2833 !important;
}

.pageSelectButton{
  color:#66FCF1;
  border-color: #66FCF1;
  margin-left: 1px !important; 
  margin-right: 1px !important;
}

select.searchCountryDropdown{
  height: 44px;
  max-width: 160px; 
  width: 100%;
  background-color: #ebeef2;
}

.streamingService{
  max-width: 100px;
  height: auto;
  border-radius: 25%;
}
searchBar{
  height: 44px;
  min-width: 200px !important;
  z-index: 0;
}