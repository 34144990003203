.my-popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  padding: 5px;
}
.my-popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].my-popup-content {
  width: 400px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}
.my-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].my-popup-overlay {
  background: transparent;
}
.boldText{
    font-weight: bold;
}
.noInfoText{
  color:#878684 !important;
}
.ratingLogo{
  height: 25px;
  width: auto;
}
.contentButton{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:grey;
  border-radius: 50%;
  max-width: 40px;
  max-height: 40px;
}
.buttonIcon{
  color:white !important
}
.row{
  display: flex;
  flex-direction: row;
}
.col{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  width: fit-content;
}
.serviceLogo{
  width: 100%;
  max-width: 75px;
  height: auto;
  border-radius: 6px;
}
.serviceText{
  font-size:12px;
  text-align: center;
}
.exclamationIcon{
  font-size: 100px;
}
div.detailsContainer{
  background-color: rgba(0,0,0, 0.7);
  border-radius: 15px;
}
button.backButton{
  max-width: 80px; 
  max-height: 40px; 
  background-color: grey;
  border-color: grey;
}
img.poster{
  max-height: auto; 
  max-width: 200px; 
  width: 100%;
  padding-bottom: 10px;
}
.rating{
  border-style: solid;
  border-radius: 4px; 
  border-color: grey; 
  font-size: 12px;
}
.qualityRating{
  display: inline-block;
}
div.popupText{
  color: black;
}

