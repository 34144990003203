nav {
    width: 80%;
    margin: 0 auto;
    /*background: #282A35;*/
    background: #0B0C10;
    padding-left: 25px;
    padding-right: 25px;
}

nav ul {
    list-style: none;
    text-align: center;
}
nav ul li {
    display: inline-block;
}
nav ul li a {
    display: block;
    font-family: helvetica;
    padding: 15px;
    text-decoration: none;
    color: #66FCF1;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 10px;
    opacity: 1 !important;
}
nav ul li a,
nav ul li a:after,
nav ul li a:before {
    transition: all .5s;
}
nav ul li a:hover {
    color: #45A29E;
}
/* stroke */
nav.stroke ul li a,
nav.fill ul li a {
    position: relative;
}
nav.stroke ul li a:after,
nav.fill ul li a:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: #45A29E;
    height: 1px;
}
nav.stroke ul li a:hover:after {
    width: 100%;
}
nav.fill ul li a {
    transition: all 2s;
}
nav.fill ul li a:after {
    text-align: left;
    content: '.';
    margin: 0;
    opacity: 0;
}
nav.fill ul li a:hover {
    color: #023f1c;
    z-index: 1;
}
nav.fill ul li a:hover:after {
    z-index: -10;
    animation: fill 1s forwards;
    -webkit-animation: fill 1s forwards;
    -moz-animation: fill 1s forwards;
    opacity: 1;
}
nav.navbarContainer{
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 2;
    min-height: 12%;
}
img.logo{
    width: 100%; 
    max-width: 300px;
    height: auto;
}
div.logoContainer{
    flex-grow: 1;
    width: fit-content;
    text-align: left;
}
div.navbarTextContainer{
    width: fit-content;
}