
/*Keeps content below fixed Nav*/
body{
  padding-top: 80px;
  font-family: helvetica;
}
/*Adjusts padding for phone screens*/
@media screen and (max-width: 768px) {
  body { padding-top: 130px; }
  .homePadding {padding-top: 15px;}
  nav ul li a {
    font-size: 14px;
    padding: 8px !important;
    padding-top: 25px !important;
    margin: 0 2px !important;
  }
}
.homePadding{
  padding-top: 65px;
}


