.selectButton{
    background-color:#66FCF1;
    color: #1F2833;
    border-color: #0B0C10;
}
.selectButton:hover{
    background-color: transparent;
    color: #66FCF1;
    border-color: #66FCF1;
}
div.buttonPadding{
    padding-bottom: 15px;
}
img.poster{
    max-height: auto; 
    max-width: 100px; 
    width: 100%;
    padding-bottom: 10px;
}
div.posterContainer{
    display: flex;
    flex-wrap: wrap;
    flex-grow: 2;
}
.contentTitle{
    padding-bottom: 10px;
}
div.resultTextContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: left;
    flex-grow: 1;
}
.noResultsFound{
    padding-top: 20px;;
}